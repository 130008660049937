import React, { useState, useEffect } from "react";
import moment, { parseTwoDigitYear } from "moment";

const ScheduleTable = ({ data }) => {
  const [currentMonth, setCurrentMonth] = useState(moment().month());
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [currentWeek, setCurrentWeek] = useState(moment().week());
  const value = data;

  try {
    if (
      value.ngayHoc.batDau < 2 ||
      value.ngayHoc.batDau > 8 ||
      value.ngayHoc.batDau > value.ngayHoc.ketThuc ||
      value.ngayHoc.ketThuc < value.ngayHoc.batDau ||
      value.ngayHoc.ketThuc > 8 ||
      value.ngayHoc.ketThuc < 2
    )
      throw new Error("Ngày học không hợp lệ!");
  } catch (error) {
    return <p>{error}</p>;
  }
  let tableHeader = [];
  for (let i = value.ngayHoc.batDau; i <= value.ngayHoc.ketThuc; i++) {
    if (i === 8)
      tableHeader.push({
        value: "CN",
        index: i,
      });
    else
      tableHeader.push({
        value: `T${i}`,
        index: i,
      });
  }
  let tableBody = new Array(value.tietHoc.soTiet).fill("");
  let flag = [];
  tableBody.map((row, m) => {
    flag.push([]);
    tableHeader.map((col, n) => {
      flag[m].push(0);
      return null;
    });
    return null;
  });

  return (
    <div className="grid justify-items-center w-full p-5">
      <div className="flex flex-col w-full text-center h-20 max-w-sm text-gray-200">
        <p>Tuần</p>
        <p className="text-primary text-lg font-bold">
          {`${moment()
            .week(currentWeek)
            .startOf("week")
            .format("DD/MM/YYYY")} - ${moment()
            .week(currentWeek)
            .endOf("week")
            .format("DD/MM/YYYY")}`}
        </p>
      </div>
      <div className="block overflow-x-auto grid justify-items-center w-full">
        <table className="bg-white/[0.4] rounded-lg shadow w-fit">
          <thead>
            <tr className="bg-white/[.7] rounded rounded-t-lg uppercase p-2">
              <th className="w-10">
                <p className="font-semibold text-sm uppercase p-2 text-gray-600"></p>
              </th>
              {tableHeader.map((header, index) => {
                if (
                  (header.index === 8 ? 0 : header.index - 1) === moment().day()
                )
                  var style =
                    "font-semibold text-sm uppercase p-2 text-sky-600 min-w-[100px]";
                else
                  var style =
                    "font-semibold text-sm uppercase p-2 text-gray-600 min-w-[100px]";
                return (
                  <th key={`d-${header.index}`} className="w-16 md:w-36">
                    <p className={style}>{header.value}</p>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableBody.map((body, i) => {
              return (
                <tr
                  key={`row-${i}`}
                  className="border border-0 border-gray-400/[0.25] border-b text-center"
                >
                  <td
                    key={`tiet${i + 1}`}
                    className="text-base border-0 border-gray-500 border-r-2 md:p-2 w-8"
                  >
                    {/* <p className="font-semibold uppercase text-sm w-8">{i + 1}</p> */}
                    <p className="uppercase text-sm">
                      {value.tietHoc.chiTiet[i]}
                    </p>
                  </td>
                  {tableHeader.map(function (header, j) {
                    let empty = true;
                    let result = {
                      maMon: "",
                      tenMon: "",
                      chiTiet: {},
                    };
                    if (flag[i][j] === 0) {
                      value.monHoc.map(function (monHoc, k) {
                        monHoc.chiTiet.map((chiTiet) => {
                          if (
                            chiTiet.tietBatDau === i + 1 &&
                            chiTiet.thu === header.index &&
                            moment()
                              .week(currentWeek)
                              .day(header.index === 8 ? 0 : header.index - 1) >=
                              moment(chiTiet.ngayBatDau, "DD/MM/YYYY") &&
                            moment()
                              .week(currentWeek)
                              .day(header.index === 8 ? 0 : header.index - 1) <=
                              moment(chiTiet.ngayKetThuc, "DD/MM/YYYY")
                          ) {
                            empty = false;
                            result.maMon = monHoc.maMon;
                            result.tenMon = monHoc.tenMon;
                            result.chiTiet = chiTiet;
                            for (k = i; k < i + result.chiTiet.soTiet; k++) {
                              flag[k][j] = 1;
                            }
                          }
                          return 0;
                        });
                        return 0;
                      });
                      if (
                        (header.index === 8 ? 0 : header.index - 1) ===
                        moment().day()
                      )
                        var style =
                          "bg-primary text-white border border-white min-w-[100px]";
                      else
                        var style =
                          "bg-gray-300/[0.8] text-gray-500 border border-white focus:bg-primary/[0.8] hover:bg-primary/[0.8] focus:text-white hover:text-white min-w-[100px]";
                      if (empty) {
                        return (
                          <td key={`tp${j}${i}`}>
                            <p className="font-semibold uppercase"></p>
                          </td>
                        );
                      } else
                        return (
                          <td
                            key={`tp${j}${i}`}
                            rowSpan={result.chiTiet.soTiet}
                            className={style}
                          >
                            <p className="text-sm w-full p-1">
                              <span className="font-bold text-sm">
                                {result.tenMon && <>{result.tenMon}</>}
                              </span>
                              <br />
                              {result.chiTiet.maLop}
                              <br />
                              {result.chiTiet.phong}
                            </p>
                          </td>
                        );
                    }
                    return null;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScheduleTable;
