import React, { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { motion } from "framer-motion";
import { FcLike, FcClock } from "react-icons/fc";
import { v4 as uuid } from "uuid";

var matrix = [];
for (var i = 0; i < 6; i++) {
  matrix[i] = new Array(7).fill(null);
}

const ScheduleCalendar = ({ data }) => {
  const [currentDate, setCurrentDate] = useState(moment().date());
  const [currentMonth, setCurrentMonth] = useState(moment().month());
  const [currentYear, setCurrentYear] = useState(moment().year());

  //   console.log(data);
  function getDayOfMonth() {
    var value = moment()
      .year(currentYear)
      .month(currentMonth)
      .date(currentDate)
      .day();
    if (value == 0) {
      return 6;
    } else {
      return value - 1;
    }
  }

  const onCurrentDate = () => {
    setCurrentDate(moment().date());
    setCurrentMonth(moment().month());
    setCurrentYear(moment().year());
  };

  const dayOfWeek = ["H", "B", "T", "N", "S", "B", "C"];
  const month = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

  function initMatrix() {
    // console.log("Thiết lập ma trận");
    var tempMatrix = [];
    for (var i = 0; i < 6; i++) {
      tempMatrix[i] = new Array(7).fill(null);
    }
    var firstDayInMonth = moment()
      .year(currentYear)
      .month(currentMonth)
      .startOf("month")
      .day();
    var daysInMonth = moment()
      .year(currentYear)
      .month(currentMonth)
      .daysInMonth();
    var j = firstDayInMonth;
    console.log(
      "Ngày đầu tiên trong tháng",
      month[currentMonth],
      "là thứ",
      firstDayInMonth == 0 ? dayOfWeek[6] : dayOfWeek[firstDayInMonth - 1]
    );
    var i = 0;
    if (firstDayInMonth == 0) {
      tempMatrix[0][6] = 1;
      var j = 7;
    } else {
      tempMatrix[0][firstDayInMonth - 1] = 1;
      var j = firstDayInMonth;
    }
    for (var day = 2; day <= daysInMonth; day++) {
      if (j > 6) {
        i++;
        j = 0;
      }
      tempMatrix[i][j] = day;
      //   console.log("Postion(", i, ",", j, "):", tempMatrix[i][j]);
      j++;
    }
    return tempMatrix;
  }

  const [monthMatrix, setMonthMatrix] = useState(initMatrix);
  const [list, setList] = useState([]);

  function onMonthChange(type) {
    switch (type) {
      case "add":
        if (currentMonth == 11) {
          setCurrentYear(currentYear + 1);
          setCurrentMonth(0);
        } else setCurrentMonth(currentMonth + 1);
        break;
      case "subtract":
        if (currentMonth == 0) {
          setCurrentYear(currentYear - 1);
          setCurrentMonth(11);
        } else setCurrentMonth(currentMonth - 1);
        break;
      default:
    }
    setCurrentDate(1);
  }

  async function transformData() {
    var currentFullDate = moment([currentYear, currentMonth, currentDate]);
    var temp = [];
    try {
      for (var monHoc of data?.monHoc) {
        for (var chiTiet of monHoc?.chiTiet) {
          if (
            currentFullDate >= moment(chiTiet?.ngayBatDau, "DD/MM/YYYY") &&
            currentFullDate <= moment(chiTiet?.ngayKetThuc, "DD/MM/YYYY") &&
            chiTiet?.thu == getDayOfMonth() + 2
          ) {
            temp.push({
              maMon: monHoc?.maMon,
              tenMon: monHoc?.tenMon,
              ...chiTiet,
            });
          }
        }
      }
    } catch (error) {}
    setList(temp);
  }

  useEffect(() => {
    transformData();
    console.log(list);
  }, [currentDate, currentMonth, currentYear, data]);

  useEffect(() => {
    setMonthMatrix(initMatrix);
  }, [currentMonth]);

  return (
    <div className="flex flex-col p-5 w-full max-w-lg">
      <div className="flex flex-col w-full text-center h-24">
        <p className="text-gray-200 m-0 font-bold">{currentYear}</p>
        <p className="text-white text-lg font-bold">
          <span
            className="text-xl cursor-pointer text-primary"
            onClick={() => onMonthChange("subtract")}
          >{` - `}</span>
          Tháng {month[currentMonth]}
          <span
            className="text-xl cursor-pointer text-primary"
            onClick={() => onMonthChange("add")}
          >{` + `}</span>
        </p>
        <p
          className="text-primary text-sm font-semibold cursor-pointer"
          onClick={onCurrentDate}
        >
          Hôm nay
        </p>
      </div>
      <div className="flex justify-center w-full">
        <table className="overflow-x bg-white/[0.4] rounded-lg shadow">
          <thead className="rounded">
            <tr className="bg-white/[.7] rounded rounded-t-lg">
              {dayOfWeek.map((value, i) => {
                return (
                  <th key={`h-${i}`}>
                    <p className="font-semibold text-sm uppercase p-2 text-gray-600">
                      {value}
                    </p>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {monthMatrix.map((week, i) => {
              if (!week.every((currentValue) => currentValue == null))
                return (
                  <tr key={`w-${i}`}>
                    {week.map((day, j) => {
                      return (
                        <td className="w-10 h-10 text-gray-200" key={`d-${j}`}>
                          {day == moment().date() &&
                          currentMonth == moment().month() &&
                          currentYear == moment().year() ? (
                            <p
                              className="font-semibold text-sm text-center cursor-pointer text-white"
                              onClick={() => setCurrentDate(day)}
                            >
                              {day == currentDate ? (
                                <span className="border border-primary rounded p-2">
                                  {day}
                                </span>
                              ) : (
                                day
                              )}
                            </p>
                          ) : day ? (
                            <p
                              className="font-semibold text-sm text-center cursor-pointer"
                              onClick={() => setCurrentDate(day)}
                            >
                              {day == currentDate ? (
                                <span className="border border-primary rounded p-2">
                                  {day}
                                </span>
                              ) : (
                                day
                              )}
                            </p>
                          ) : (
                            <p
                              key={`d-${j}`}
                              className="text-sm text-center cursor-pointer"
                            ></p>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
            })}
          </tbody>
        </table>
      </div>
      {currentDate && (
        <div className="flex flex-col mt-8 gap-3">
          <p className="flex font-semibold text-gray-200 gap-1 m-0">
            Ngày
            <span className="text-primary">{`${
              currentDate < 10 ? `0${currentDate}` : currentDate
            }/${
              currentMonth + 1 < 10 ? `0${currentMonth + 1}` : currentMonth + 1
            }/${currentYear}`}</span>
          </p>
          {list?.length > 0 ? (
            list?.map((mon, index) => {
              return (
                // border-0 border-l-4 border-white pt-0 pl-1
                // <div
                //   className="flex flex-col gap-1 p-2 border border-gray-800 rounded-0 rounded-lg"
                //   key={uuid().slice(0, 8)}
                // >
                //   <p className="flex align-center items-center text-gray-200 font-medium m-0 p-0 gap-1">
                //     <FcClock />
                //     {data.tietHoc.chiTiet[mon.tietBatDau - 1].substr(0, 5)}-
                //     {data.tietHoc.chiTiet[
                //       mon.tietBatDau + mon.soTiet - 1 - 1
                //     ].substr(8, 5)}
                //   </p>

                // </div>
                <motion.div
                  key={`list${index}`}
                  transition={{ duration: 0.25 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="flex flex-row w-full bg-[#8c8c8c] rounded-lg gap-0 rounded-lg text-white"
                >
                  <div className="w-3/5 p-2">
                    <p className="uppercase font-bold">{mon.tenMon}</p>
                    <p className="text-sm">{mon.maMon}</p>
                  </div>
                  <div className="w-2/5">
                    <div className="bg-primary w-full h-full rounded-r-lg p-2">
                      <p className="font-semibold text-sm text-white">
                        {
                          data?.coSo?.find((item) =>
                            mon?.maCoSo?.includes(item.maCoSo)
                          )?.tenCoSo
                        }
                      </p>
                      <p className="font-bold text-xl text-white">
                        {mon.phong}
                      </p>
                      <p className="font-bold text-white">
                        Tiết {mon.tietBatDau}-{mon.tietBatDau + mon.soTiet - 1}
                      </p>
                    </div>
                  </div>
                </motion.div>
              );
            })
          ) : (
            <motion.div
              transition={{ duration: 0.25 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="grid grid-auto-rows justify-items-center content-center w-full"
            >
              <p className="text-center text-gray-600">Không có tiết học nào</p>
            </motion.div>
          )}
        </div>
      )}
    </div>
  );
};

export default ScheduleCalendar;
