import React, { useState, useEffect } from "react";
import ScheduleTable from "./table";
import ScheduleCalendar from "./calendar";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { getSheet } from "../../service/google-sheet";

let config = {
  tietHoc: {
    soTiet: 10,
    chiTiet: [
      "07:30 - 08:15",
      "08:15 - 09:00",
      "09:00 - 09:45",
      "10:00 - 10:45",
      "10:45 - 11:30",
      "13:00 - 13:45",
      "13:45 - 14:30",
      "14:30 - 15:15",
      "15:30 - 16:15",
      "16:15 - 17:00",
    ],
  },
  ngayHoc: {
    batDau: 2,
    ketThuc: 7,
  },
  coSo: [
    {
      maCoSo: "A",
      tenCoSo: "Toà A",
    },
    {
      maCoSo: "B",
      tenCoSo: "Toà B",
    },
    {
      maCoSo: "C",
      tenCoSo: "Toà C",
    },
    {
      maCoSo: "E",
      tenCoSo: "Toà E",
    },
  ],
};

const Schedule = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState({});
  const [viewMode, setViewMode] = useState("day");
  function viewModeToggle() {
    viewMode === "week" ? setViewMode("day") : setViewMode("week");
  }
  async function fetchData() {
    setLoading(true);
    const [courseData, scheduleData] = await Promise.all([
      getSheet(process.env.REACT_APP_COURSE_SHEET_NAME),
      getSheet(process.env.REACT_APP_SCHEDULE_SHEET_NAME),
    ]);
    const monHoc = courseData.map((item1) => {
      const chiTiet = scheduleData
        .filter((item2) => {
          return item2[0].includes(item1[0]);
        })
        .map((item3) => {
          return {
            thu: Number(item3[1]),
            tietBatDau: Number(item3[2]),
            soTiet: Number(item3[3]),
            phong: item3[4],
            maCoSo: item3[5],
            maLop: item3[0],
            ngayBatDau: item3[6],
            ngayKetThuc: item3[7],
          };
        });
      return {
        maMon: item1[0],
        tenMon: item1[1],
        chiTiet: chiTiet,
      };
    });
    setDataSource({
      ...config,
      monHoc: await Promise.resolve(monHoc),
    });
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log(dataSource);
  }, [dataSource]);
  return (
    <>
      <Helmet>
        <title>{`Schedule`}</title>
        <meta name="description" content={`Schedule`} />
        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="apple-mobile-web-app-capable" content="yes"></meta>
        <meta name="mobile-web-app-capable" content="yes"></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      {loading === true && (
        <div className="flex flex-col justify-center content-center items-center w-full h-full fixed z-[50] backdrop-blur-sm bg-white/30">
          <p className="m-0 text-sm">
            <svg
              className="animate-spin h-5 w-5 mr-3"
              viewBox="0 0 24 24"
            ></svg>
            Đang tải dữ liệu...
          </p>
        </div>
      )}
      <div className="bg-[#121212] w-full min-h-screen overflow-x">
        <div className="flex flex-col justify-center content-center items-center w-full">
          {/* <div className="md:hidden flex w-full justify-between content-center bg-white shadow h-fit p-3 font-semibold sticky z-50 top-0 left-0">
            <IoIosArrowBack className="hidden text-[#007aff] m-0 text-lg mt-1 font-bold" />
            <p className="flex text-sm w-36 gap-1">
              <FcPlanner className="w-5 h-5 m-0 mr-1" />
              Thời khoá biểu
            </p>
            <IoIosSettings className="hidden text-[#007aff] m-0 text-lg mt-1 font-bold" />
          </div> */}
          {viewMode === "day" && (
            <motion.button
              transition={{ duration: 0.25 }}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              className="absolute z-10 bottom-5 right-5 font-semibold text-white text-sm cursor-pointer w-fit bg-primary p-3 rounded"
              onClick={viewModeToggle}
            >
              {"Tuần"}
              <AiOutlineArrowLeft />
            </motion.button>
          )}
          {viewMode === "week" && (
            <motion.button
              transition={{ duration: 0.25 }}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 50 }}
              className="absolute z-10 bottom-5 left-5 font-semibold text-white text-sm cursor-pointer w-fit bg-primary p-3 rounded"
              onClick={viewModeToggle}
            >
              <AiOutlineArrowRight />
              {" Ngày"}
            </motion.button>
          )}
          {viewMode === "week" && (
            <motion.div
              transition={{ duration: 0.25 }}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 50 }}
              className="flex justify-center content-center items-center w-full overflow-hidden"
            >
              <ScheduleTable data={dataSource} />
            </motion.div>
          )}
          {viewMode === "day" && (
            <motion.div
              transition={{ duration: 0.25 }}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              className="flex justify-center content-center items-center w-full"
            >
              <ScheduleCalendar data={dataSource} />
            </motion.div>
          )}
        </div>
      </div>
    </>
  );
};

export default Schedule;
